import * as contentful from 'contentful';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { customFetch } from '../utils/utils';
import { setLoading } from './app.reducer';
import { getBaseUrl } from '../utils/api';

export const getClient = async () => {
  const targetCTF = {
    environment: process.env.REACT_APP_CONTENTFUL_TARGET_ENVIRONMENT,
    preview: process.env.REACT_APP_CONTENTFUL_PREVIEW === 'true',
  };
  let params = {
    space: '5h3y0cofo0sv',
    environment: targetCTF.environment,
    accessToken: 'pE3mUTGDi0EtUXQSuh0cPIUhohBSxfVbaJ0tQfM_3C8',
  };
  if (targetCTF.preview) {
    params = {
      ...params,
      accessToken: 'Do8dWdvnag46yLmv_TTQfhYw-2Sh3lO8FBYbW2LvOQY',
      // @ts-ignore
      host: 'preview.contentful.com',
    };
  }
  return contentful.createClient(params);
};

export const getLinksBetweenSurveysAndDocuments = async () => {
  return await customFetch(`${getBaseUrl()}/contentful/getLinksBetweenSurveysAndDocuments`, 'POST', JSON.stringify({}), true);
};

export const getLinksBetweenSurveysAndRegistries = async () => {
  return await customFetch(`${getBaseUrl()}/contentful/getLinksBetweenSurveysAndRegistries`, 'POST', JSON.stringify({}), true);
};

export const getLinksBetweenQuestionsAndSurveys = async () => {
  return await customFetch(`${getBaseUrl()}/contentful/getLinksBetweenQuestionsAndSurveys`, 'POST', JSON.stringify({}), true);
};

export const fetchFullQuestions = async () => {
  return await customFetch(`${getBaseUrl()}/contentful/getQuestions`, 'POST', JSON.stringify({}), true);
};

export const fetchFullDocuments = async () => {
  return await customFetch(`${getBaseUrl()}/contentful/getDocuments`, 'POST', JSON.stringify({}), true);
};

export const fetchFullSurveys = async () => {
  return await customFetch(`${getBaseUrl()}/contentful/getSurveys`, 'POST', JSON.stringify({}), true);
};

const fetchDocuments = async lang => {
  return await customFetch(`${getBaseUrl()}/contentful/getDocumentsInfos`, 'POST', JSON.stringify({}), true);
};

const fetchSurveys = async lang => {
  return await customFetch(`${getBaseUrl()}/contentful/getSurveysInfos`, 'POST', JSON.stringify({}), true);
};

const fetchRegistries = async lang => {
  return await customFetch(`${getBaseUrl()}/contentful/getRegistriesInfos`, 'POST', JSON.stringify({}), true);
};

const fetchUserActions = async lang => {
  return await customFetch(`${getBaseUrl()}/contentful/getActions`, 'POST', JSON.stringify({}), true);
};

export const fetchAllContentfulData = createAsyncThunk(
  'Contentful/FETCH_ALL',
  async (
    args: {
      lang?: any;
      user?: any;
    },
    thunkApi
  ) => {
    let documents = await fetchDocuments(args?.lang);
    let registries = await fetchRegistries(args?.lang);
    let surveys = await fetchSurveys(args?.lang);
    return {
      documents,
      registries,
      surveys,
    };
  }
);

export const fetchUserActionsContentfulData = createAsyncThunk(
  'Contentful/FETCH_USER_ACTIONS',
  async (
    args: {
      lang?: any;
      user?: any;
    },
    thunkApi
  ) => {
    let userActions = await fetchUserActions(args?.lang);
    return {
      userActions,
    };
  }
);

export const contentfulReducer = createSlice({
  name: 'CONTENTFUL',
  initialState: {
    documents: [],
    registries: [],
    surveys: [],
    userActions: [],
  },
  reducers: {
    resetContentfulReducer: state => {
      return {
        documents: [],
        registries: [],
        surveys: [],
        userActions: [],
      };
    },
    enhanceSurvey: (state, action) => {
      state.surveys = state.surveys.map(survey => {
        if (survey.id === action.payload.id) {
          survey = action.payload;
        }
        return survey;
      });
    },
    clearContentfulActions: state => {
      state.userActions = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAllContentfulData.fulfilled, (state, action) => {
      state.documents = action.payload.documents;
      state.registries = action.payload.registries;
      state.surveys = action.payload.surveys;
      // state.userActions = action.payload.userActions;
      setLoading(false);
    });
    builder.addCase(fetchUserActionsContentfulData.fulfilled, (state, action) => {
      state.userActions = action.payload.userActions;
    });
  },
});

export const { resetContentfulReducer, enhanceSurvey, clearContentfulActions } = contentfulReducer.actions;

export const selectAllContentfulData = state => state.contentful;

export const selectDocuments = state => state.contentful.documents;

export const selectRegistries = state => state.contentful.registries;

export const selectSurveys = state => state.contentful.surveys;

export const selectDocumentsById = (state, id) => state.contentful.documents.filter(document => document._id === id);

export const selectUserActions = state => state.contentful.userActions;

export default contentfulReducer.reducer;
