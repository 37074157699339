import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import dayjs from 'dayjs';

import { FaCheck } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { FaArrowsRotate, FaRegFaceGrimace, FaRegFaceGrinBeamSweat, FaXmark } from 'react-icons/fa6';

import { actionsStatus } from '@components-new/common/constants';
import { Chip, Container } from '@components-new/atoms';

import { Button } from '@components/Button';
import { InputComponent } from '@components/form/InputComponent';
import { Chips } from '@components/chips/Chips';
import { SharedItem } from '@components/collaborators/SharedItem';
import { Modal } from '@components/modal/Modal';
import { FileExplorer } from '@components/file-explorer/FileExplorer';

import { handleLegalOfficerAuditDecision, selectSelectedAccount, updateActionPlan } from 'src/reducers/account.reducer';

import useDidUpdate from '@hooks/useDidUpdate';

import { BUTTON_COLORS } from '@utils/constants';
import { getAuditQuestionInfosByName } from '@utils/utils';
import { fetchContentfulEntryById, fetchSubContractorResponses } from '@utils/api';

import { ActionDetailsContainer, ActionFooter, ActionsContainer, ArrowContainer, ContainerTitle, CustomSpinner, DivContainer, List, ListItem, ResponseContainer, ResponsesHeader } from './Audit_.style';

const Audits = () => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const account = useSelector(selectSelectedAccount);

  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [surveyData, setSurveyData] = useState(null);
  const [subContractorData, setSubContractorData] = useState(null);
  const [showAvailableDocuments, setShowAvailableDocuments] = useState({ show: false, fileName: null });

  useEffect(() => {
    const fetchData = async () => {
      const entryId = '2LGGPkFMcjg5G9SztSFP5y';
      try {
        const result = await fetchContentfulEntryById(entryId);
        setSurveyData(result);
      } catch (err) {
        console.log("🚀 ~ err:", err)
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectEntitiesOptions.length === 1) {
      setLoading(true);
      setSelectedEntity(selectEntitiesOptions[0].value);
    }
    setLoading(false);
  }, []);

  const entities = useMemo(() => {
    return account.entities.filter(
      entity => entity?.actionPlan?.actions?.length > 0 && entity.actionPlan.actions.some(action => action.category === 'Audit')
    );
  }, [account]);

  const selectEntitiesOptions = useMemo(() => {
    return entities?.map(entity => {
      return { label: entity.name, value: entity._id };
    });
  }, [entities]);

  const entity = useMemo(() => {
    return entities.find(entity => entity._id === selectedEntity);
  }, [entities, selectedEntity]);

  const action = useMemo(() => {
    return entity?.actionPlan?.actions.find(
      action =>
        action.id === selectedAction?.id &&
        action.relatedProductId === selectedAction?.relatedProductId &&
        action.versionProductId === selectedAction?.versionProductId &&
        action.subContractor.name === selectedAction?.subContractor?.name
    );
  }, [entity?.actionPlan?.actions, selectedAction]);

  const handleClickEntity = (name, entityId) => {
    if (selectedEntity !== entityId) {
      setSelectedEntity(entityId);
      if (selectedAction) {
        setSelectedAction(null);
      }
    }
  };

  useDidUpdate(async () => {
    const subContractorId = selectedAction?.subContractor?.id;
    if (!subContractorId) {
      setSubContractorData(null);
      return;
    }

    try {
      const result = await fetchSubContractorResponses(subContractorId);

      if (result && Object.keys(result).length > 0) {
        setSubContractorData(result);
      } else {
        setSubContractorData(null);
      }
    } catch (error) {
      console.error("Error fetching subcontractor responses:", error);
      setSubContractorData(null);
    }
  }, [selectedAction?.subContractor?.id]);

  const handleClickAction = action => {
    setSelectedAction(action);
  };

  const handleLegalOfficerDecision = async type => {
    setLoading(true);
    await dispatch(
      handleLegalOfficerAuditDecision({
        accountId: account._id,
        entityId: entity._id,
        actionId: action.id,
        relatedProductId: action.relatedProductId,
        versionProductId: action.versionProductId,
        type,
        subContractorId: action.subContractor.id,
      })
    );
    const subContractorId = selectedAction?.subContractor?.id;
    const result = await fetchSubContractorResponses(subContractorId);
    if (result?.status && subContractorData) {
      setSubContractorData(prevData => { return { ...prevData, status: result.status } });
    }
    setLoading(false);
  };

  const formatSubcontractorQuestionAndResponses = (key, value) => {
    const questionInfo = getAuditQuestionInfosByName(surveyData, key);
    const questionLabel = questionInfo.label;
    if (Array.isArray(value)) {
      return { questionLabel, response: value.map(elt => questionInfo.options?.find(item => item.value === elt)?.label).join(', ') };
    }
    const response = questionInfo.options ? questionInfo.options?.find(item => item.value === value || value.includes(item.value))?.label : value;
    return { questionLabel, response };
  };

  const handleUpdateActionsStatus = upgrade => {
    setLoading(true);
    const actions = [selectedAction];
    dispatch(updateActionPlan({ accountId: account._id, entityId: selectedEntity, actions, upgrade: upgrade }));
    setSelectedAction(null);
    setLoading(false);
  };

  return (
    <Container grid columnTemplate='1fr 3fr' height='100%' gap='20px' >
      <Container flex column>
        <InputComponent
          placeholder='Choisir une entité'
          type={'select'}
          options={selectEntitiesOptions}
          onChange={handleClickEntity}
          sharable={false}
          defaultValue={selectEntitiesOptions.length === 1 && selectEntitiesOptions[0]}
          noOptionsMessage={() => 'Aucune entité disponible'}
        />
        {selectedEntity && (
          <>
            <ArrowContainer>
              <IoIosArrowDown size={22} />
            </ArrowContainer>
            <ActionsContainer>
              <ContainerTitle>
                <span>List des Audits</span>
              </ContainerTitle>
              <hr />
              <Container overflowY='auto'>
                <List>
                  {entity.actionPlan.actions
                    ?.filter(item => item.category === 'Audit' && item.active)
                    .map((item, index) => {
                      const { color, icon } = actionsStatus[item.status]
                      return (
                        <ListItem
                          key={index}
                          onClick={() => handleClickAction(item)}
                          active={
                            item.id === selectedAction?.id &&
                            item.relatedProductId === selectedAction?.relatedProductId &&
                            item.versionProductId === selectedAction?.versionProductId &&
                            item.subContractor.name === selectedAction?.subContractor?.name
                          }>
                          <Chip icon={icon} color={color} backgroundColor='transparent' />
                          <span>Auditer : {item.subContractor.name}</span>
                          <IoIosArrowForward size={16} />
                        </ListItem>
                      );
                    })}
                </List>
              </Container>
            </ActionsContainer>
          </>
        )}
      </Container>
      {selectedAction && (
        <ActionDetailsContainer>
          {subContractorData ? (
            <>
              <Container grid columnTemplate='3fr 1fr' gap='30px'>
                <Container flex column>
                  <DivContainer center bolder>
                    <span>Nom de l'entreprise</span>
                    {subContractorData.companyName}
                  </DivContainer>
                  <DivContainer center bolder >
                    <span>Email de responsable</span>
                    {subContractorData.responsableEmail}
                  </DivContainer>
                  <DivContainer center bolder >
                    <span>Rôle de responsable</span>
                    {subContractorData.responsablePosition}
                  </DivContainer>
                </Container>
                <ActionFooter>
                  {
                    !subContractorData.status ? (
                      loading ? (
                        <CustomSpinner color={BUTTON_COLORS.default} />
                      ) : (
                        <>
                          <Button
                            label='valider'
                            icon={FaCheck}
                            type='success'
                            onClick={() => handleLegalOfficerDecision('validate')}
                            disabled={loading}
                          />
                          <Button
                            label='Refuser'
                            icon={ImCancelCircle}
                            type='danger'
                            onClick={() => handleLegalOfficerDecision('refuse')}
                            disabled={loading}
                          />
                        </>
                      )
                    ) : subContractorData?.status === 'conforme' ? (
                      <Chips label={'Conforme'} color='#00b894' icon={FaCheck} />
                    ) : (
                      <>
                        <Chips label={'Non Conforme'} color='#ff7675' icon={FaXmark} />
                        <Button
                          label='Revalider'
                          icon={FaArrowsRotate}
                          type='success'
                          onClick={() => handleLegalOfficerDecision('validate')}
                          disabled={loading}
                          iconSize={16}
                        />
                      </>
                    )

                    // TODO!: To be added if needed when the decision was made
                    //   <strong>
                    //     {dayjs(
                    //       action?.subContractor?.status === 'conforme' ? action?.subContractor?.validatedAt : action?.subContractor?.rejectedAt
                    //     ).format('DD MMMM YYYY [à] HH:mm:ss')}
                    //   </strong>
                  }
                </ActionFooter>
              </Container>
              <ResponsesHeader>Les réponses du sous-traitant : </ResponsesHeader>
              <ResponseContainer>
                {subContractorData?.responses && Object.entries(subContractorData.responses).map(([key, value]: [string, string]) => {
                  const { questionLabel, response } = formatSubcontractorQuestionAndResponses(key, value);
                  const regex = /\.(pdf|docx|xlsx)$/i;
                  return (
                    <SharedItem
                      questionValue={response}
                      value={questionLabel}
                      valid={true}
                      key={key}
                      type='questions'
                      cursor={regex.test(value) ? 'pointer' : null}
                      onClickFn={() => {
                        if (regex.test(value)) {
                          setShowAvailableDocuments({ show: true, fileName: value });
                        }
                      }}
                    />
                  );
                })}
              </ResponseContainer>
            </>
          ) : subContractorData?.contactEmail ? (
            <Container flex alignItems='center' justifyContent='center' height={'100%'} column>
              <FaRegFaceGrinBeamSweat size={40} />
              <p>Le sous-traitant n'as pas encore répondu au question.</p>
            </Container>
          ) : (
            <>
              <Container alignSelf='end'>
                <Button type='danger' onClick={() => handleUpdateActionsStatus('downgrade')} label={t('common.downgrade')} customColor='#36B894' disabled={loading} icon={ImCancelCircle} />
              </Container>
              <Container flex alignItems={'center'} justifyContent={'center'} height={'100%'} column>
                <FaRegFaceGrimace size={40} />
                <p>Le client n'a pas encore ajouté le contact du sous-traitant.</p>
              </Container>
            </>
          )}
        </ActionDetailsContainer>
      )}

      {selectedEntity && showAvailableDocuments.show && selectedAction && (
        <Modal
          size={{ height: '100%' }}
          show={showAvailableDocuments?.show}
          title={t('actionPlan.available_documents')}
          onClose={() => setShowAvailableDocuments({ show: false, fileName: null })}>
          <FileExplorer
            selectedEntity={{ _id: selectedEntity }}
            selectedFileKey={`public/${account._id}/${selectedEntity}/Audits/${selectedAction.subContractor?.name}/${showAvailableDocuments.fileName}`}
            showEntitySelection={false}
            disableBreadcrumb
          />
        </Modal>
      )}
    </Container>
  );
};

export default Audits;


