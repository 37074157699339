import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { SelectChangeEvent, Divider, MenuItem, Avatar } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { BsTable } from 'react-icons/bs';
import { FaChevronUp } from 'react-icons/fa';
import { FcLowPriority, FcMediumPriority, FcHighPriority } from 'react-icons/fc';
import { GrTask } from 'react-icons/gr';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { MdClose } from 'react-icons/md';

import { updateActionPlan, updateCommentActionPlan, updateCommentStateActionPlan } from 'src/reducers/account.reducer';
import { setErrors, setLoading } from 'src/reducers/app.reducer';

import { actionsStatus, actionsUrgencyComplexity } from '@components-new/common/constants';
import { Chip, FilesIcons } from '@components-new/atoms';
import Chat from '@components/chat/Chat';
import Drawer from '@components/drawer';
import { Button } from '@components/Button';

import { addEventTracking, emphasizedText, retrieveUserDetails, stringAvatar } from '@utils/utils';
import { getDocumentsListFromDirectory } from '@utils/api';

import {
  DivContainer,
  Tag,
  CustomSelect,
  CustomDatePicker,
  ResponsibleContainer,
  ResponsibleItem,
  TabsContainer,
  Tab,
  Badge,
  Link,
  ProofFilesContainer,
} from '../ActionPlan_.style';
import { ActionProps, EntityProps } from '../ActionPlan.types';

interface UserProps {
  id: string;
  name: string;
  firstName: string;
  email: string;
}

interface ActionDetailsProps {
  selectedAccount: any;
  selectedAction: ActionProps;
  activeAccount: any;
  selectedEntity: EntityProps;
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAction: React.Dispatch<React.SetStateAction<ActionProps>>;
  selectedDrawerItemID: string | null;
  currentUser: any;
  admin: boolean;
  editedAction: ActionProps;
  setShowSubcontractorInputModal: React.Dispatch<React.SetStateAction<boolean>>;
  isAnyNewComment: (action: any, currentUserId) => boolean;
  impersonated: any;
}

const ActionDetails: React.FC<ActionDetailsProps> = ({
  selectedAction,
  activeAccount,
  selectedAccount,
  setSelectedAction,
  selectedEntity,
  showDrawer,
  setShowDrawer,
  selectedDrawerItemID,
  currentUser,
  admin,
  editedAction,
  setShowSubcontractorInputModal,
  isAnyNewComment,
  impersonated,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const [selectedItemID, setSelectedItemID] = useState<string | null>(selectedDrawerItemID);
  const [proofFiles, setProofFiles] = useState([]);

  useEffect(() => {
    setSelectedItemID(selectedDrawerItemID);
  }, [selectedDrawerItemID]);

  const getDocumentsFromS3 = async () => {
    const documents = await getDocumentsListFromDirectory(
      selectedAccount._id,
      selectedEntity._id,
      `user-uploads/actions/${selectedEntity._id + '_' + selectedAction.relatedProductId + '_' + selectedAction.id + '_' + selectedAction.versionProductId
      }`
    );
    return documents;
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      const documents = await getDocumentsFromS3();
      setProofFiles(documents);
    };

    selectedAccount && selectedAction && selectedEntity && showDrawer && fetchDocuments();
  }, [selectedAccount, selectedAction, selectedEntity, showDrawer]);

  const datePickerRef = useRef(null);

  const comments = useMemo(() => selectedAction.comment ?? [], [selectedAction.comment]);

  const isActionChanged = (selectedAction, editedAction) => {
    return JSON.stringify(selectedAction) !== JSON.stringify(editedAction);
  };

  const handleValidateEditedAction = () => {
    dispatch(setLoading(true));
    addEventTracking('Action Plan', 'Update Action');
    dispatch(updateActionPlan({ accountId: activeAccount._id, entityId: selectedEntity._id, actions: selectedAction }));
    setSelectedAction({});
    setShowDrawer(prevState => !prevState);
    dispatch(setLoading(false));
  };
  const handleDateChange = date => {
    setSelectedAction(prevState => ({ ...prevState, dueDate: dayjs(date).format('DD-MM-YYYY') }));
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedAction(prevState => ({ ...prevState, status: event.target.value }));
  };

  const updateCommentAction = async message => {
    let updatedAction = {
      ...selectedAction,
      comment: [...selectedAction.comment, message],
    };

    setSelectedAction(updatedAction);

    let sharedItem = {
      entityId: selectedEntity._id,
      shareId:
        'entity/' +
        selectedEntity._id +
        '/product/' +
        selectedAction.relatedProductId +
        '/action/' +
        selectedAction.id +
        '/version/' +
        selectedAction.versionProductId,
      actionId: selectedAction.id,
      relatedProductId: selectedAction.relatedProductId,
      versionProductId: selectedAction.versionProductId,
      message: true,
    };

    await dispatch(
      updateCommentActionPlan({
        accountId: selectedAccount ? selectedAccount._id : activeAccount._id,
        entityId: selectedEntity._id,
        actionId: selectedAction.id,
        relatedProductId: selectedAction.relatedProductId,
        versionProductId: selectedAction.versionProductId,
        comment: message,
        host: currentUser,
        sharedItem: sharedItem,
      })
    );
  };

  const handleDateClick = event => {
    const target = event.target;
    if (target.classList.contains('MuiInputBase-input')) {
      datePickerRef.current.querySelector('button').click();
    }
  };

  const updateSeenStatus = () => {
    if (isAnyNewComment(selectedAction, currentUser._id) && Array.isArray(selectedAction.comment) && selectedAction.comment.length > 0) {
      setSelectedAction(selectedAction);
      dispatch(
        updateCommentStateActionPlan({
          accountId: selectedAccount ? selectedAccount._id : activeAccount._id,
          entityId: selectedEntity._id,
          actionId: selectedAction.id,
          userId: currentUser._id,
        })
      );
    }
  };

  const deleteComment = filteredComments => {
    const newSelectedAction = {
      ...selectedAction,
      comment: filteredComments,
    };
    setSelectedAction(newSelectedAction);
    dispatch(
      updateActionPlan({
        accountId: activeAccount._id,
        entityId: selectedEntity._id,
        actions: newSelectedAction,
      })
    );
  };

  const getUserInfo = () => {
    const user: UserProps = {
      name: currentUser.name ? currentUser.name : currentUser.email,
      id: currentUser._id,
      firstName: currentUser.firstName ?? '',
      email: currentUser.email,
    };
    return user;
  };

  const removeResponsibleFromAction = responsibleId => {
    let newResponsibleArray = selectedAction.responsible.filter(resp => resp !== responsibleId);
    if (newResponsibleArray.length === 0) {
      dispatch(
        setErrors({
          title: 'Avertissement',
          message: t('actionDetails.remove_responsible_error'),
        })
      );
      return;
    }
    setSelectedAction(prevState => ({ ...prevState, responsible: newResponsibleArray }));
  };

  return (
    <>
      {showDrawer && (
        <Drawer
          setOpen={() => {
            setShowDrawer(false);
            setSelectedAction({});
          }}
          open={showDrawer}
          width='66%'
          header={
            <TabsContainer>
              <Tab selected={'detail' === selectedItemID} onClick={() => setSelectedItemID('detail')}>
                <span>
                  <Badge>
                    <GrTask />
                  </Badge>
                  {selectedAction.category === 'Audit' ? t('actionDetails.audit_tab_label') : t('actionDetails.action_tab_label')}
                </span>
              </Tab>
              <Tab selected={'chat' === selectedItemID} onClick={() => setSelectedItemID('chat')}>
                <span>
                  <Badge>
                    <IoChatbubblesOutline size={24} />
                  </Badge>
                  {t('common.discussion')}
                </span>
              </Tab>
            </TabsContainer>
          }>
          <>
            {selectedItemID === 'chat' ? (
              <Drawer.Content>
                <Chat
                  deleteMessage={deleteComment}
                  isLoading={false}
                  messages={comments}
                  user={getUserInfo()}
                  handleMessage={updateCommentAction}
                  updateSeenStatus={updateSeenStatus}
                  admin={impersonated || selectedAccount}
                />
              </Drawer.Content>
            ) : (
              <>
                <Drawer.Content>
                  <DivContainer center bolder>
                    <span>{t('common.action')}</span>
                    {selectedAction.title}
                  </DivContainer>
                  {/* Task Description */}
                  <DivContainer>
                    <span>{t('common.description')}</span>
                    <div className='justify-text' dangerouslySetInnerHTML={{ __html: emphasizedText(selectedAction.description) }}></div>
                  </DivContainer>
                  {/* Task explanation */}
                  {selectedAction.explanation && (
                    <DivContainer>
                      <span>{t('actionDetails.explanation_label')}</span>
                      <div className='justify-text' dangerouslySetInnerHTML={{ __html: emphasizedText(selectedAction.explanation) }}></div>
                    </DivContainer>
                  )}
                  {/* Task Precision */}
                  {selectedAction.precision && (
                    <DivContainer>
                      <span>{t('common.precision')}</span>
                      <div className='justify-text' dangerouslySetInnerHTML={{ __html: emphasizedText(selectedAction.precision) }}></div>
                    </DivContainer>
                  )}
                  <Divider variant='middle' sx={{ margin: '16px' }} />
                  {/* Product Title */}
                  <DivContainer size='small' center height>
                    <span>{t('common.product')}</span>
                    {selectedAction.isEntityScoop ? selectedEntity.name : selectedAction.versionProductName}
                  </DivContainer>
                  {/* Category */}
                  <DivContainer size='small' center height>
                    <span>{t('common.category')}</span>
                    <Tag>{selectedAction.category}</Tag>
                  </DivContainer>
                  {/* Urgency */}
                  <DivContainer size='small' center height>
                    <span>{t('common.urgency')}</span>
                    <Tag urgency={selectedAction.urgency}>
                      {selectedAction.urgency === 'low' && <FcLowPriority fontSize={24} />}
                      {selectedAction.urgency === 'medium' && <FcMediumPriority fontSize={24} />}
                      {selectedAction.urgency === 'high' && <FcHighPriority fontSize={24} />}
                      {t(actionsUrgencyComplexity[selectedAction.urgency]?.label)}
                    </Tag>
                  </DivContainer>
                  {/* Status */}
                  <DivContainer size='small' center height>
                    <span>{t('common.status')}</span>
                    {admin || selectedAction.category === 'Audit' ? (
                      <Chip
                        variant='text'
                        label={t(actionsStatus[selectedAction.status]?.label) || t('common.not_activated')}
                        color={actionsStatus[selectedAction.status]?.color}
                        icon={actionsStatus[selectedAction.status]?.icon}
                      />
                    ) : (
                      <CustomSelect
                        value={selectedAction.status}
                        onChange={(e: SelectChangeEvent) => handleStatusChange(e)}
                        IconComponent={props => <FaChevronUp {...props} inputprops={{ readOnly: admin }} />}>
                        <MenuItem value={'pending'}>{t('common.status_pending')}</MenuItem>
                        <MenuItem value={'ongoing'}>{t('common.status_ongoing')}</MenuItem>
                        <MenuItem value={'completed'}>{t('common.status_completed')}</MenuItem>
                      </CustomSelect>
                    )}
                  </DivContainer>
                  {/* SubContractor Contact */}
                  {selectedAction.category === 'Audit' && (
                    <DivContainer
                      size='small'
                      center
                      height
                      onClick={() => {
                        !selectedAction?.subContractor?.contactEmail && setShowSubcontractorInputModal(true);
                      }}>
                      <span>{t('actionDetails.subcontractor_contact_label')}</span>
                      {selectedAction.subContractor?.contactEmail || (
                        <span style={{ cursor: 'pointer' }}>{t('actionDetails.subcontractor_contact_not_specified')}</span>
                      )}
                    </DivContainer>
                  )}
                  {/* Due Date */}
                  <DivContainer size='small' center height>
                    <span>{t('common.due_date')}</span>
                    {admin || selectedAction.category === 'Audit' ? (
                      selectedAction.dueDate ? (
                        dayjs(selectedAction.dueDate, 'DD-MM-YYYY').format('DD-MM-YYYY').toString()
                      ) : (
                        '-- / -- / ----'
                      )
                    ) : (
                      <div onClick={handleDateClick}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <CustomDatePicker
                            editable
                            value={dayjs(selectedAction.dueDate, 'DD-MM-YYYY')}
                            onChange={date => {
                              handleDateChange(date);
                            }}
                            format={'DD-MM-YYYY'}
                            disablePast
                            slots={{
                              openPickerIcon: BsTable,
                            }}
                            ref={datePickerRef}
                            timezone={'Europe/Paris'}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                  </DivContainer>
                  {/* CreatedAt */}
                  <DivContainer size='small' center height>
                    <span>{t('common.creation_date')}</span>
                    {dayjs(selectedAction?.createdAt).format('DD-MM-YYYY')}
                  </DivContainer>
                  {/* Task Responsible */}
                  <DivContainer size='small'>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '40px',
                      }}>
                      {t('actionDetails.responsible_label')}
                    </span>
                    <ResponsibleContainer>
                      {selectedAction?.responsible?.length ? (
                        selectedAction?.responsible?.map((responsibleId, index) => {
                          const { name = '', email = '' } = retrieveUserDetails(responsibleId, selectedAccount) || {};
                          return (
                            <ResponsibleItem key={index}>
                              <Avatar key={index} {...stringAvatar((name ?? email).toUpperCase(), responsibleId)} />
                              <span style={{ color: 'grey' }}>{name ?? email}</span>
                              {!admin && <MdClose onClick={() => removeResponsibleFromAction(responsibleId)} />}
                            </ResponsibleItem>
                          )
                        })
                      ) : (
                        <ResponsibleItem>
                          <span>{t('actionDetails.responsible_not_assigned')}</span>
                        </ResponsibleItem>
                      )}
                    </ResponsibleContainer>
                  </DivContainer>
                  {proofFiles.length !== 0 && (
                    <DivContainer size='small'>
                      <span style={{ display: 'flex', alignItems: 'center', height: '40px' }}>{t('common.proof')}</span>
                      <ResponsibleContainer>
                        {proofFiles.map(file => {
                          return (
                            <ProofFilesContainer key={file.name} onClick={() => window.open(file.link, '_blank')}>
                              <FilesIcons filesType={[file.fileType.toLowerCase()]} />
                              <span>{file.name?.replaceAll('_', ' ').slice(0, file.name.lastIndexOf('.'))}</span>
                            </ProofFilesContainer>
                          );
                        })}
                      </ResponsibleContainer>
                    </DivContainer>
                  )}
                  {selectedAction?.proof?.link && (
                    <DivContainer size='small'>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '40px',
                        }}>
                        {t('actionDetails.proofLink')}
                      </span>
                      <ResponsibleContainer>
                        <ResponsibleItem>
                          <Link href={selectedAction?.proof?.link} target='_blank' rel='noopener noreferrer'>
                            {selectedAction?.proof?.link}
                          </Link>
                        </ResponsibleItem>
                      </ResponsibleContainer>
                    </DivContainer>
                  )}
                </Drawer.Content>
                {/* Footer du Drawer */}
                {!admin && selectedAction.category !== 'Audit' && (
                  <Drawer.Footer align='full'>
                    <Button
                      label={t('common.confirm')}
                      onClick={e => {
                        e.preventDefault();
                        handleValidateEditedAction();
                      }}
                      disabled={!isActionChanged(selectedAction, editedAction)}
                    />
                    <Button
                      label={t('common.cancel')}
                      type='light'
                      onClick={e => {
                        e.preventDefault();
                        setShowDrawer(false);
                        setSelectedAction({});
                      }}
                    />
                  </Drawer.Footer>
                )}
              </>
            )}
          </>
        </Drawer>
      )}
    </>
  );
};

export default ActionDetails;
